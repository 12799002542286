<template>
  <div class="orderList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <div>
        <span>订单号：</span>
        <el-input v-model="list.coding" placeholder="请输入订单号"></el-input>
      </div>
      <div v-if="currentOrderType == 5 || currentOrderType == 6">
        <span>升级订单编号：</span>
        <el-input v-model="list.upCoding" placeholder="升级订单编号"></el-input>
      </div>
      <div>
        <span>商品名称：</span>
        <el-input
          v-model="list.prodName"
          placeholder="请输入商品名称"
        ></el-input>
      </div>
      <div>
        <span>联系人：</span>
        <el-input v-model="list.linkMan" placeholder="请输入联系人"></el-input>
      </div>
      <div>
        <span>联系电话：</span>
        <el-input v-model="list.phone" placeholder="请输入电话"></el-input>
      </div>
      <div>
        <span>下单人手机号：</span>
        <el-input v-model="list.userPhone" placeholder="请输入电话"></el-input>
      </div>
      <div>
        <span>支付状态：</span>
        <el-select
          v-model="list.payStatus"
          clearable
          @clear="onClear"
          placeholder="请选择支付状态"
        >
          <el-option
            v-for="item in payStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div class="time">
        <span>下单时间：</span>
        <el-date-picker
          @change="onChange"
          v-model="payTime"
          type="datetimerange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </div>
      <div class="time" v-if="Number(type) === 1">
        <span>入离时间：</span>
        <el-date-picker
          @change="onChange1"
          v-model="playTime"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </div>
      <div class="time" v-if="Number(type) !== 1">
        <span>游玩时间：</span>
        <!-- <el-date-picker
          v-model="beginTime"
          align="right"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        ></el-date-picker> -->
        <el-date-picker
          @change="onChange1"
          v-model="playTime"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <div>
        <span>订单来源：</span>
        <el-select
          v-model="list.source"
          clearable
          @clear="onClear1"
          placeholder="选择来源"
        >
          <el-option
            v-for="item in paySource"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div>
        <span>供应商:</span>
        <el-select v-model="list.supplierId" placeholder="选择供应商">
          <el-option
            v-for="item in supplierOptions"
            :key="item.cateId"
            :label="item.cateName"
            :value="item.cateId"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span>活动Id:</span>
        <el-input
          v-model="list.activityId"
          placeholder="请输入活动Id"
          type="number"
        ></el-input>
      </div>
      <div>
        <span>活动区域Id:</span>
        <el-input
          v-model="list.areaId"
          placeholder="请输入活动区域Id"
          type="number"
        ></el-input>
      </div>
      <div>
        <el-button class="btn" type="primary" @click="getOrderList()">
          查找
        </el-button>
        <el-button class="btn" type="primary" @click="createForm()">
          生成报表
        </el-button>
        <keep-alive>
          <el-button class="btn" type="primary" @click="createForm(2)">
            生成全部报表
          </el-button>
        </keep-alive>
        <el-input style="opacity: 0" placeholder=""></el-input>
      </div>
    </div>
    <el-table :data="orderList" style="width: 100%" border>
      <el-table-column
        align="center"
        type="selection"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orderId"
        label="订单主键"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orderProdName"
        label="产品名称"
        width="250"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orderCoding"
        label="订单编码"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orderAddTime"
        label="下单时间"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orderCount"
        label="购买数量"
        width="width"
      ></el-table-column>
      <el-table-column
        v-if="Number(type) !== 4"
        align="center"
        prop="orderBeginTime"
        label="	游玩开始时间"
        width="width"
      ></el-table-column>
      <el-table-column
        v-if="Number(type) !== 4"
        align="center"
        prop="orderEndTime"
        label="	游玩结束时间"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orderLinkMan"
        label="	联系人"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orderPhone"
        label="	电话号码"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orderStatus"
        label="	订单状态"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="orderPayWay"
        label="	支付方式"
        width="width"
      ></el-table-column>
      <el-table-column align="center" label="下单人" width="width">
        <template slot-scope="{ row }">
          {{ row.userNickName }} {{ row.userPhone }}
          {{ row.userProfitLevel }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="isPartner"
        label="	下单人是否店长"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="activityTitle"
        label="	活动标题"
        width="width"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="areaName"
        label="	区域名称"
        width="width"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="width">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="mini"
            @click="onOrderdetail(row.orderCoding)"
          >
            查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    ></el-pagination>
    <ul class="bottom_text">
      <li>总数:{{ totalNum }}</li>
      <li>总金额:{{ totalMoney }}</li>
      <li>总销售额:{{ totalSales }}</li>
    </ul>
    <div class="orderMsg" v-if="isNewOder"></div>
  </div>
</template>
<script>
import { supList } from "../../api/supList.js";
import {
  orderList,
  createForm,
  selectNoReview,
  createFormAll,
} from "../../api/order";
export default {
  name: "orderList",
  
  props: {
    type: {
      type: [Number, String],
      required: true,
    },
  },
  watch: {
    type() {
      console.log("路由切换");
      this.payTime = null;
      this.playTime = null;
      this.beginTime = null;
      this.endTime = null;

      this.list.addBeginTime = null;
      this.list.addEndTime = null;
      this.list.currentPage = 1;

      this.getOrderList();
    },
  },
  computed: {
    title() {
      switch (Number(this.type)) {
        case 1:
          return "酒店订单";

        case 2:
          return "门票订单";

        case 3:
          return "套餐订单";

        case 4:
          return "万旅卡订单";

        case 5:
          return "拼团订单";

        case 6:
          return "秒杀订单";

        case 7:
          return "美食订单";

        case 8:
          return "预售订单";

        case 9:
          return "超值礼包订单";
      }
    },
  },
  data() {
    return {
      currentOrderType: "",
      isNewOder: false,

      supplierOptions: [
        { cateId: -1, cateName: "全部" },
        { cateId: 0, cateName: "自营" },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      payStatus: [
        { value: 1, label: "待支付" },
        { value: 2, label: "已支付" },
        { value: 3, label: "已退款" },
        { value: 4, label: "申请退款" },
      ],
      paySource: [
        { value: null, label: "全部" },
        { value: 0, label: "小程序" },
        { value: 1, label: "H5" },
        { value: 2, label: "PC" },
        { value: 3, label: "Android" },
        { value: 4, label: "IOS" },
      ],
      payTime: null,
      playTime: null,
      orderList: [],
      source: false,
      list: {
        coding: "",
        upCoding: "",
        addBeginTime: null,
        addEndTime: null,
        prodName: "",
        linkMan: "",
        phone: "",
        payStatus: null,
        orderStatus: null,
        currentPage: 1,
        pageSize: 10,
        source: null,
        supplierId: -1,
        activityId: 0,
        areaId: 0,
        userPhone: "",
      },
      beginTime: "",
      endTime: "",
      pagination: {},
      timer: null,
      newOrderList: [],
      totalNum: "",
      allSales: "",
      totalMoney: "",
      totalSales: "",
    };
  },
  created() {
    this.list.payStatus = 2;
    this.getOrderList();
    this.getSupplierList();
  },
  updated() {
    // this.list.payStatus = 2
    this.currentOrderType = this.$route.params.type;

    // if(this.currentOrderType == 5 ||this.currentOrderType == 6){
    //   this.currentOrderType = false
    // }else{
    //   this.currentOrderType = true
    // }
    if (this.currentOrderType == 5 || this.currentOrderType == 6) {
      this.source = false;
    } else {
      this.source = true;
    }
  },
  methods: {
    getSupplierList() {
      supList().then((res) => {
        this.supplierOptions.push(...res.data.data);
      });
    },
    onClear1() {
      this.list.source = null;
    },
    async createForm(index) {
      console.log(index, "index");
      let API;
      index == 2 ? (API = createFormAll) : (API = createForm);
      if (!this.payTime && index !== 2) {
        this.$message.error("请选择下单时间");
      } else {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let date = +new Date();
        const { data } = await API({
          payStatus: this.list.payStatus,
          coding: this.list.coding,
          addBeginTime: this.list.addBeginTime,
          addEndTime: this.list.addEndTime,
          prodName: this.list.prodName,
          linkName: this.list.linkName,
          phone: this.list.phone,
          beginTime: this.beginTime,
          endTime:  this.endTime,
          supplierId: this.list.supplierId,
          type: index == 2 ? "0" : this.$route.params.type,
          activityId: this.list.activityId,
          areaId: this.list.areaId,
        });
        let url = window.URL.createObjectURL(data); //转换文件流未url
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", `万旅网小程序V2.0订单报表_${date}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        loading.close();
      }
    },
    onChange(value) {
      console.log(value);
      if (value === null) {
        this.list.addBeginTime = null;
        this.list.addEndTime = null;
      } else {
        this.list.addBeginTime = value[0];
        this.list.addEndTime = value[1];
        this.getOrderList();
      }
    },
    onChange1(value) {
      console.log(value);
      if (value === null) {
        this.beginTime = null;
        this.endTime = null;
      } else {
        this.beginTime = value[0];
        this.endTime = value[1];
      }
    },
    onOrderdetail(coding) {
      console.log(coding);
      this.$router.push(`/refundOrderdetail/${coding}?type=${this.type}`);

      // if (Number(this.type) === 5) {
      //   this.$router.push(`/groupBuyOrderdetail/${this.type}/${coding}`);
      // } else {
      // }
    },
    async getOrderList() {
      this.list.activityId = this.list.activityId || 0;
      this.list.areaId = this.list.areaId || 0;
      const { data } = await orderList({
        type: Number(this.type),
        ...this.list,
        beginTime: this.beginTime,
        endTime: this.endTime,
      });
      if (data.code == 0) {
        this.allSales = data.msg;
        this.pagination = data.pagination;
        this.totalNum = data.pagination.total;
        this.orderList = data.list;
        let cutNum = this.allSales.indexOf("_");
        this.totalMoney = this.allSales.slice(0, cutNum);
        this.totalSales = this.allSales.slice(cutNum + 1, this.allSales.length);
      } else {
        this.$message.error(data.msg);
      }
    },
    onClear() {
      this.list.payStatus = null;
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getOrderList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getOrderList();
    },
  },
};
</script>
<style lang="less" scoped>
.orderList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    div {
      margin-top: 20px;
      width: 25%;
      display: flex;
      align-items: baseline;
      .el-date-editor {
        width: 300px;
      }
      .btn {
        margin-left: 20px;
      }
    }
    .el-select {
      width: 200px;
    }
    span {
      margin: 0 10px;
    }
    .el-input {
      width: 200px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
  .bottom_text {
    font-weight: bold;
    float: right;
    padding-top: 54px;
    padding-right: 10px;
    display: flex;
    li {
      margin: 0 10px;
    }
  }
}
</style>
